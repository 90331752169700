<template>
  <div id="app"
    class="privacy-policy page-template-default page page-id-3 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation"
      class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="https://gbapks.com.ru/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp"
                title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="https://gbapks.com.ru/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>

        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="https://gbapks.com.ru/" aria-current="page">Home</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="https://gbapks.com.ru/gbwhatsapp-downloadpage/">Download</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="https://gbapks.com.ru/blogs/">Blogs</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <article id="post-3" class="post-3 page type-page status-publish">
              <div class="inside-article">
                <header class="entry-header" aria-label="Content">
                  <h1 class="entry-title" itemprop="headline">
                    About Us
                  </h1>
                </header>

                <div class="entry-content" itemprop="text">
                  <p>
                    Welcome to <a href="https://gbapks.com.ru" class="jump-url">https://gbapks.com.ru</a> — your go-to source for the latest and most reliable GB WhatsApp mods and updates! We’re passionate about helping you unlock more features and customization options to enhance your messaging experience.
                  </p>
                  <p>
                    Our goal is to provide safe, up-to-date, and easy-to-install APKs, along with clear guides so you can make the most of your app. Whether you’re looking to personalize your chats or explore new functionalities, we’ve got you covered.
                  </p>
                  <p>
                    At <a href="https://gbapks.com.ru" class="jump-url">https://gbapks.com.ru</a>, we value simplicity, security, and user satisfaction. We’re constantly updating our content to ensure you get the best versions available, all in one place.
                  </p>
                  <p>
                    Thanks for choosing us — let’s make messaging better together!
                  </p>
                </div>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="https://gbapks.com.ru/privacy/">Privacy Policy</a> |
            <a href="https://gbapks.com.ru/about-us/">About Us</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><a href="https://gbapks.com.ru/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';

export default {
  name: 'Privacy',
  data () {
    return {
      pageName: 'privacy',
    };
  },
  mounted () { },
};
</script>
